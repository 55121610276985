import { useEffect } from 'react'
import AppRouter from './routers'
import RankContextProvider from './contexts/ranks/RankContext'
import { getCompanyCodeParam, getCompanyFavicon } from './contexts/functions'
import LastLocationContextProvider from './contexts/location/LastLocationContext'
import 'antd/dist/antd.min.css'
import InitializeGoogleAnalytics from './utils/google-analytics'

function App() {
    // console.log = () => { }
    
    useEffect(() => {
        InitializeGoogleAnalytics();
    }, [])

    // Dynamically update favicon
    useEffect(() => {
        let link = document.querySelector("link[rel='shortcut icon']")
        if (!link) {
            link = document.createElement('link')
            link.rel = 'shortcut icon'
            document.getElementsByTagName('head')[0].appendChild(link)
        }
        link.href = getCompanyFavicon(getCompanyCodeParam())
    }, [getCompanyCodeParam()])

    return (
        <LastLocationContextProvider>
            <RankContextProvider>
                <AppRouter />
            </RankContextProvider>
        </LastLocationContextProvider>
    )
}

export default App
