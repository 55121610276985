import React, { useEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { WarningFilled } from "@ant-design/icons";
import moment from "moment";
import { LcStorage } from "../../utils/storage";
import {
  ImgKasumiRankBronze,
  ImgKasumiRankGold,
  ImgKasumiRankSilver,
} from "../../assets/img";
import {
  SELECTION_SCREEN,
  POLICY_SCREEN,
  ERROR_SCREEN,
} from "../../constants/path";
import stringJp from "../../constants/string";
import {
  COMPANY_CODES,
  kasumiCompany,
  company,
} from "../../constants/configCompany";
import {
  PAST_RANKS,
  MY_RANK,
  ACTION_TYPE,
  ACCOUNT_NAME,
  SELECTION_STATE,
  IS_SUCCESS,
} from "../../constants";

import { getMemberRanks, verifyIntroduceCode } from "../../contexts/actions/memberInfo";
import { useRank } from "../../contexts/ranks/RankContext";
import {
  checkInternetWorking,
  findRank,
  formatDate,
  gapBetween2Dates,
  getRankImgSrc,
  getAccountIdParam,
  getCompanyCodeParam,
  getMyRank,
  getPastRanks,
  getSelectionState,
  checkJoinRankCanGivePoint,
} from "../../contexts/functions";
import { useWindowSize } from "../../contexts";
import { useLastLocation } from "../../contexts/location/LastLocationContext";

import MainLayout from "../../layout/MainLayout";
import {
  ButtonCall,
  ButtonContactEmail,
  StyledButton,
  ModalConfirmOutRank,
} from "../../components";
import "./indexHomeScreen.scss";
import ModalRenewalAuto from "../../components/Modal/ModalRenewalAuto";
import { TrackGoogleAnalyticsEvent } from "../../utils/google-analytics";
import { Input } from "antd";
import ModalWarning from "../../components/Modal/ModalError/modalWarning";

const ContentRender = (props) => {
  const {
    userInfo,
    listRanks,
    isOnline,
    errorMsg,
    isFetchRanksError,
    navigate,
    saveLastLocation,
    setSelectedRank,
    setOpenMdOutRank,
    handleClickRegisterRank,
    setErrorIntroduceCodeMsg,
    setOldIntroduceCode,
    oldIntroduceCode,
    errorIntroduceCodeMsg,
    continueNext
  } = props;
  const companyCode = getCompanyCodeParam();

  const [visibleRenewalAuto, setVisibleRenewalAuto] = React.useState(false);
  const [mesRenewalAuto, setMesRenewalAuto] = React.useState("");

  const { RANK_ID } = kasumiCompany;

  const [windowWidth] = useWindowSize();

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--dynamic-vw",
      `${windowWidth * 0.01}px`
    );

    const userIdEl = document.getElementsByClassName("user-id")?.item(0);
    document.documentElement.style.setProperty(
      "--user-id-width",
      `${userIdEl?.offsetWidth}px`
    );

    const userNameEl = document.getElementsByClassName("user-name")?.item(0);

    if (userNameEl?.scrollHeight > userNameEl?.offsetHeight) {
      userNameEl.classList.add("is-overflow");
    }

    while (userNameEl?.scrollHeight > userNameEl?.offsetHeight) {
      // Remove characters from name until the text and the overflow indicator fit
      userNameEl.innerText = userNameEl?.innerText.slice(0, -1);
    }
  }, [windowWidth]);

  const isExpiringSoon = userInfo?.expDate
    ? gapBetween2Dates(userInfo?.expDate) <= 30
    : false;

  const handleClickBtnRenewal = () => {
    const expRenewal = formatDate(
      moment(userInfo?.expDate)?.add(1, "days"),
      "LLL"
    )?.replace(/(00:00)/g, "");
    const rank = getMyRank();
    // Set is_give_point to false for the rank object when the Renewal button is clicked.
    const newValueRank = {
      ...rank,
      is_give_point: false,
    };
    function handleClickNavigate() {
      setSelectedRank(newValueRank);
      saveLastLocation();
      LcStorage.setLocalJson(SELECTION_STATE, {
        rank,
        action: ACTION_TYPE.RENEWAL,
      });

      if (companyCode === COMPANY_CODES.MAX_VALUE) {
        return navigate({
          pathname: `/${SELECTION_SCREEN}`,
          search: createSearchParams({
            action: ACTION_TYPE.CHANGE,
          }).toString(),
        });
      }

      return navigate({
        pathname: `/${POLICY_SCREEN}`,
        search: createSearchParams({
          action: ACTION_TYPE.RENEWAL,
        }).toString(),
      });
    }

    if (rank?.account_rank_group_id === RANK_ID.GOLD) {
      if (userInfo.differenceGold > 0) {
        // TH1: Gold -> Gold
        setVisibleRenewalAuto(true);
        setMesRenewalAuto(
          `${
            stringJp.msp1_update_rank +
            expRenewal +
            "からGold" +
            stringJp.msp3_update_rank
          } `
        );
      } else {
        handleClickNavigate();
      }
    } else if (rank?.account_rank_group_id === RANK_ID.SILVER) {
      if (userInfo.differenceGold > 0) {
        // TH5: Silver -> Silver && total > 120000
        setVisibleRenewalAuto(true);
        setMesRenewalAuto(
          `${
            stringJp.msp1_update_rank +
            expRenewal +
            "からGold" +
            stringJp.msp2_update_rank
          } `
        );
      } else if (userInfo.differenceSilver > 0 && userInfo.differenceGold < 0) {
        // TH5: Silver -> Silver && 119999< total < 120000
        setVisibleRenewalAuto(true);
        setMesRenewalAuto(
          `${
            stringJp.msp1_update_rank +
            expRenewal +
            "からSilver" +
            stringJp.msp3_update_rank
          } `
        );
      } else {
        handleClickNavigate();
      }
    } else if (rank?.account_rank_group_id === RANK_ID.BRONZE) {
      if (userInfo.differenceGold > 0) {
        // TH9: Bronze -> Bronze && total > 120000
        setVisibleRenewalAuto(true);
        setMesRenewalAuto(
          `${
            stringJp.msp1_update_rank +
            expRenewal +
            "からGold" +
            stringJp.msp2_update_rank
          } `
        );
      } else if (userInfo.differenceSilver > 0 && userInfo.differenceGold < 0) {
        // TH9: Bronze -> Bronze && 119999< total < 120000
        setVisibleRenewalAuto(true);
        setMesRenewalAuto(
          `${
            stringJp.msp1_update_rank +
            expRenewal +
            "からSilver" +
            stringJp.msp2_update_rank
          } `
        );
      } else {
        handleClickNavigate();
      }
    } else {
      //not rank
      handleClickNavigate();
    }
  };

  const handleClickBtnChangeRank = () => {
    if (companyCode === COMPANY_CODES.KASUMI) {
      setSelectedRank(null);
      saveLastLocation();
      LcStorage.setLocalJson(SELECTION_STATE, {
        rank: null,
        action: ACTION_TYPE.CHANGE,
      });
  
      navigate({
        pathname: `/${SELECTION_SCREEN}`,
        search: createSearchParams({
          action: ACTION_TYPE.CHANGE,
        }).toString(),
      });
    }
    if (companyCode === COMPANY_CODES.MARUETSU || companyCode === COMPANY_CODES.MAX_VALUE) {
      const expRenewal = formatDate(
        moment(userInfo?.expDate)?.add(1, "days"),
        "LLL"
      )?.replace(/(00:00)/g, "");
      if (userInfo.differenceGold > 0) {
        // TH5: Silver -> Silver && total > 120000
        setVisibleRenewalAuto(true);
        setMesRenewalAuto(
          `${
            stringJp.msp1_update_rank +
            expRenewal +
            "からGold" +
            stringJp.msp2_update_rank
          } `
        );
      } else if (userInfo.differenceSilver > 0 && userInfo.differenceGold < 0) {
        // TH5: Silver -> Silver && 119999< total < 120000
        setVisibleRenewalAuto(true);
        setMesRenewalAuto(
          `${
            stringJp.msp1_update_rank +
            expRenewal +
            "からSilver" +
            stringJp.msp3_update_rank
          } `
        );
      } else {
        continueNext()
      }
    }
  };

  const handleChangeIntroduceCode = (e) => {
    setErrorIntroduceCodeMsg("");
    setOldIntroduceCode(e?.target?.value);
    LcStorage.setLocalJson("introduce_code", e?.target?.value);
  };

  const checkShowChangeRankButton = (listRanks) => {
    if (listRanks?.length <= 1) return false;

    // TODO: undo then release
    // if (companyCode === COMPANY_CODES.MAX_VALUE) {
    //   return false;
    // }

    // delete then
    if (companyCode === COMPANY_CODES.MAX_VALUE) {
      if (userInfo?.differenceGold > 0 || (userInfo?.differenceSilver > 0 && userInfo?.differenceGold < 0)) {
        return true;
      } else if (!userInfo?.rankId) {
        return true;
      }
      return false
    }

    if (companyCode === COMPANY_CODES.MARUETSU) {
      if (userInfo?.differenceGold > 0 || (userInfo?.differenceSilver > 0 && userInfo?.differenceGold < 0)) {
        return true;
      } else if (!userInfo?.rankId || userInfo?.rankId === company[COMPANY_CODES.MARUETSU]?.RANK_ID?.BRONZE) {
        return true;
      }
      return false;
    }
    return true;
  }

  return (
    <div>
      <div className="body-wrapper">
        <div className="user-info">
          <div className="user-name">
            {LcStorage.getLocalJson(ACCOUNT_NAME) || ""}
            <span>{stringJp.txt_sama}</span>
          </div>
          <div className="user-id">
            {stringJp.txt_id}
            {getAccountIdParam() || ""}
          </div>
        </div>

        {isOnline && (
          <div className="main-info-wrapper">
            {/* Rank Images & messages */}
            {userInfo?.rankId ? (
              <>
                <div className="img-rank">
                  <img src={getRankImgSrc(userInfo?.rankId)} alt="no-rank" />
                </div>

                {isExpiringSoon && (
                  <div className="expiration-warning-wrapper">
                    <WarningFilled className="icon-warning" />
                    <div>
                      <p>
                        {stringJp.msg_warning_expiration_1a}
                        <b>{stringJp.msg_warning_expiration_1b}</b>
                        {stringJp.msg_warning_expiration_1c}
                      </p>
                      <p className="txt-exp-date">
                        {stringJp.msg_expiration}
                        {formatDate(userInfo?.expDate, "LLL")}
                      </p>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="img-rank">
                  <img src={getRankImgSrc()} alt="no rank" />
                </div>

                {errorMsg ? (
                  <div className="txt-err">{errorMsg}</div>
                ) : (
                  <div className="txt-msg-register-rank">
                    <span>
                      {stringJp.msg_regis_rank_row_1a}
                      <b>{stringJp.msg_regis_rank_row_1b}</b>
                      {stringJp.msg_regis_rank_row_1c}
                    </span>
                    <span>
                      {stringJp[`${companyCode}`]?.msg_regis_rank_row_2a}
                      {windowWidth < 768 && <br />}
                      {stringJp[`${companyCode}`]?.msg_regis_rank_row_2b}
                    </span>
                  </div>
                )}

                {companyCode === COMPANY_CODES.KASUMI && (
                  <div className="list-rank-imgs">
                    <div onClick={() => handleClickRegisterRank(RANK_ID.GOLD)}>
                      <img src={ImgKasumiRankGold} alt="rank gold" />
                    </div>
                    <div
                      onClick={() => handleClickRegisterRank(RANK_ID.SILVER)}
                    >
                      <img src={ImgKasumiRankSilver} alt="rank silver" />
                    </div>
                    <div
                      onClick={() => handleClickRegisterRank(RANK_ID.BRONZE)}
                    >
                      <img src={ImgKasumiRankBronze} alt="rank bronze" />
                    </div>
                  </div>
                )}
              </>
            )}

            {(
              (companyCode === COMPANY_CODES.MAX_VALUE
             && checkJoinRankCanGivePoint(
              listRanks,
              company[COMPANY_CODES.MAX_VALUE]?.RANK_ID?.BRONZE
            ))
            || (companyCode === COMPANY_CODES.MARUETSU
              && checkJoinRankCanGivePoint(listRanks, company[COMPANY_CODES.MARUETSU]?.RANK_ID?.SILVER
              ))
          )
            && (
              <div className="introduce-code">
                <p className="introduce-code-message">
                  {stringJp.txt_introduce_code_home_screen}
                </p>
                <Input
                  size="large"
                  value={oldIntroduceCode}
                  onChange={handleChangeIntroduceCode}
                  status={errorIntroduceCodeMsg ? "error" : "normal"}
                />
              </div>
            )}

            {/* Buttons */}
            {!userInfo?.rankId ? (
              <div className="btn-register-wrapper">
                <StyledButton
                  label={stringJp[`${companyCode}`]?.title_btn_register_rank}
                  onClick={
                    isOnline && !errorMsg && !isFetchRanksError
                      ? handleClickRegisterRank
                      : null
                  }
                  style={{
                    opacity:
                      !isOnline || errorMsg || isFetchRanksError ? 0.6 : 1,
                  }}
                />
              </div>
            ) : (
              <div className="group-btn-rank-actions">
                <div className="btn-renewal-rank">
                  <StyledButton
                    label={stringJp.title_renewal_rank}
                    onClick={isExpiringSoon ? handleClickBtnRenewal : null}
                    className={!isExpiringSoon ? "btn-expired-disable" : ""}
                  />
                </div>

                {checkShowChangeRankButton(listRanks) && (
                  <div className="btn-change-rank">
                    <StyledButton
                      label={stringJp.title_change_rank}
                      onClick={handleClickBtnChangeRank}
                    />
                  </div>
                )}

                <div className="btn-out-rank">
                  <StyledButton
                    label={stringJp.text_button_out_rank}
                    onClick={() => setOpenMdOutRank(true)}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        <div className="footer-container">
          <div className="footer-divider" />
          <div className="contact-zone-wrapper">
            <h4>{stringJp.title_screen_contact}</h4>
            <div className="btn-group">
              <ButtonCall label={stringJp.title_btn_call} />
              <ButtonContactEmail label={stringJp.title_btn_email} />
            </div>
          </div>
        </div>

        <ModalRenewalAuto
          content={mesRenewalAuto}
          visible={visibleRenewalAuto}
          setVisible={setVisibleRenewalAuto}
        />
      </div>
    </div>
  );
};

const HomeScreen = () => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const { updateLastLocation } = useLastLocation();
  const {
    userInfo,
    setUserInfo,
    setSelectedRank,
    listRanks,
    fetchListRanks,
    errorMsg: errorMsgFetchRanks,
    setOpenMdRejoinPrevRank,
  } = useRank();
  const myRank = getMyRank();
  const [errorMsg, setErrorMsg] = useState("");
  const [isOnline, setIsOnline] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openMdError, setOpenMdError] = useState(false);
  const [isOpenModalOutRank, setIsOpenModalOutRank] = useState(false);
  const [errorIntroduceCodeMsg, setErrorIntroduceCodeMsg] = useState("");
  const [oldIntroduceCode, setOldIntroduceCode] = useState(
    LcStorage.getLocalJson("introduce_code")
  );

  const [visibleErrorIntroduceCode, setVisibleErrorIntroduceCode] =
    useState(false);

  const companyCode = getCompanyCodeParam()

  useEffect(() => {
    checkInternetWorking().then((value) => {
      setIsOnline(value);
    });
  }, []);

  // Navigate to screen Error
  useEffect(() => {
    if (+LcStorage.getLocalJson(IS_SUCCESS) === 1) {
      // updateLastLocation(currentLocation.pathname)
      navigate(`/${ERROR_SCREEN}`); // , { replace: true }
    }
  }, []);

  useEffect(() => {
    if (errorMsgFetchRanks && !errorMsg) setOpenMdError(true);
  }, [errorMsgFetchRanks]);

  const fetchUserInfo = () => {
    const handleDataRpGetRank = (res) => {
      if (res?.status === 200 && res?.data?.success) {
        const { data: resData } = res;

        if (resData?.past_rank_list)
          LcStorage.setLocalJson(PAST_RANKS, resData?.past_rank_list);

        LcStorage.setLocalJson(ACCOUNT_NAME, resData?.account_name || "");

        setErrorMsg("");
        setUserInfo({
          ...userInfo,
          accountId: resData?.account_id || "",
          accountName: resData?.account_name || "",
          rankId: resData?.rank_id,
          rankName: resData?.rank_name,
          startDate: resData?.rank_start_date,
          expDate: resData?.rank_expiration_date,
          differenceGold: resData?.difference_to_rank1,
          differenceSilver: resData?.difference_to_rank2,
          totalPurchasePrice: resData?.total_purchase_price,

          // ==== test ====

          // expDate: "2023/09/20",
          // differenceGold: 80000,
          // rankId: "1",
          // rankName: "Gold",
          // differenceSilver: 500000,
          // totalPurchasePrice:0
        });
      } else {
        setErrorMsg(res?.data?.message);
        LcStorage.setLocalJson(ACCOUNT_NAME, "");
      }
      setIsLoading(false);
    };

    getMemberRanks(handleDataRpGetRank);
  };

  useEffect(() => {
    // LcStorage.removeLocal(SELECTION_STATE)
    // setSelectedRank(null)

    if (!currentLocation?.search) return;

    setIsLoading(true);
    fetchUserInfo();
    fetchListRanks();

    LcStorage.setLocalJson(SELECTION_STATE, {
      ...getSelectionState(),
      rank: null,
    });

    return () => setErrorMsg("");
  }, [currentLocation?.search]);

  // Update current rank in localStorage based on rank fetched from API
  useEffect(() => {
    if (listRanks && listRanks?.length > 0) {
      const currentRank = findRank(listRanks, userInfo?.rankId);

      if (currentRank && (!myRank || !_.isEqual(currentRank, myRank))) {
        LcStorage.setLocalJson(MY_RANK, currentRank);
      } else if (!userInfo?.rankId && myRank) LcStorage.removeLocal(MY_RANK);
    }
  }, [userInfo, listRanks]);

  const saveLastLocation = () => {
    updateLastLocation(currentLocation.pathname);
  };

  const handelClickBtnRegisterRank = (rankId = "") => {
    if (oldIntroduceCode && companyCode !== COMPANY_CODES.KASUMI) {
      const handleDataRpVerifyCode = (res) => {
        if (res?.status === 412) {
          setVisibleErrorIntroduceCode(true);
          setErrorIntroduceCodeMsg(res?.data?.message);
        } else continueNext();
      };

      verifyIntroduceCode(handleDataRpVerifyCode, oldIntroduceCode);
    } else {
      continueNext(rankId)
    }
  };

  const continueNext = (rankId) => {
    if (errorMsg || !listRanks || listRanks?.length < 1) {
      if (errorMsg) {
        TrackGoogleAnalyticsEvent("errorMsg");
      }
      if (!listRanks) {
        TrackGoogleAnalyticsEvent("!listRanks");
      }
      if (listRanks?.length < 1) {
        TrackGoogleAnalyticsEvent("len<1");
      }
      return;
    }
    TrackGoogleAnalyticsEvent("passBtnTest");
    if (listRanks?.length > 1) {
      if (companyCode === COMPANY_CODES["KASUMI"]) {
        if (rankId) {
          const rank = findRank(listRanks, rankId);
          setSelectedRank(rank);
          LcStorage.setLocalJson(SELECTION_STATE, {
            rank,
            action: ACTION_TYPE.REGISTER,
          });
        }
  
        saveLastLocation();
        navigate({
          pathname: `/${SELECTION_SCREEN}`,
          search: createSearchParams({
            action: ACTION_TYPE.REGISTER,
          }).toString(),
        });
      }
      if (companyCode === COMPANY_CODES["MARUETSU"] || companyCode === COMPANY_CODES["MAX_VALUE"]) {
        const listPrevRanks = getPastRanks() || [];
        const arrayCompanyRankId = company[`${companyCode}`]?.RANK_ID;
        // for MARUETSU
        let companyRankId = arrayCompanyRankId?.SILVER;
        if (companyCode === COMPANY_CODES["MAX_VALUE"]) {
          // for MAXVALUE
          companyRankId = arrayCompanyRankId?.BRONZE;
        }
        
        const rank = findRank(listRanks, companyRankId);
        if (findRank(listPrevRanks, companyRankId)) {
          let dateCreate = findRank(listPrevRanks, companyRankId);
          let endNowDay = new Date(dateCreate?.application_start_dt);
          endNowDay?.setFullYear(
            endNowDay?.getFullYear() + 1,
            endNowDay?.getMonth() + 1,
            1
          );
          if (endNowDay) {
            if (new Date() < endNowDay) {
              setOpenMdRejoinPrevRank(true);
            } else {
              setSelectedRank(rank);
              LcStorage.setLocalJson(SELECTION_STATE, {
                rank,
                action: ACTION_TYPE.REGISTER,
              });
  
              saveLastLocation();
              navigate({
                pathname: `/${POLICY_SCREEN}`,
                search: createSearchParams({
                  action: ACTION_TYPE.REGISTER,
                }).toString(),
              });
            }
          } else {
            TrackGoogleAnalyticsEvent("endNowDayUndefine");
          }
        } else {
          setSelectedRank(rank);
          LcStorage.setLocalJson(SELECTION_STATE, {
            rank,
            action: ACTION_TYPE.REGISTER,
          });
  
          saveLastLocation();
          navigate({
            pathname: `/${POLICY_SCREEN}`,
            search: createSearchParams({
              action: ACTION_TYPE.REGISTER,
            }).toString(),
          });
        }
      }
    }
    /// sẽ không còn vào case list rank  = 1 nữa
    else if (listRanks?.length === 1) {
      const listPrevRanks = getPastRanks() || [];
      const companyRankId = Object.values(
        company[`${getCompanyCodeParam()}`]?.RANK_ID
      )[0];
      const rank = findRank(listRanks, companyRankId);
      if (findRank(listPrevRanks, companyRankId)) {
        let dateCreate = findRank(listPrevRanks, companyRankId);
        let endNowDay = new Date(dateCreate?.application_start_dt);
        endNowDay?.setFullYear(
          endNowDay?.getFullYear() + 1,
          endNowDay?.getMonth() + 1,
          1
        );
        if (endNowDay) {
          if (new Date() < endNowDay) {
            setOpenMdRejoinPrevRank(true);
          } else {
            setSelectedRank(rank);
            LcStorage.setLocalJson(SELECTION_STATE, {
              rank,
              action: ACTION_TYPE.REGISTER,
            });

            saveLastLocation();
            navigate({
              pathname: `/${POLICY_SCREEN}`,
              search: createSearchParams({
                action: ACTION_TYPE.REGISTER,
              }).toString(),
            });
          }
        } else {
          TrackGoogleAnalyticsEvent("endNowDayUndefine");
        }
      } else {
        setSelectedRank(rank);
        LcStorage.setLocalJson(SELECTION_STATE, {
          rank,
          action: ACTION_TYPE.REGISTER,
        });

        saveLastLocation();
        navigate({
          pathname: `/${POLICY_SCREEN}`,
          search: createSearchParams({
            action: ACTION_TYPE.REGISTER,
          }).toString(),
        });
      }
    } else setOpenMdError(true);
  };

  return (
    <MainLayout
      className="home-screen"
      screenTitle={stringJp.title_home_screen}
      isLoading={isLoading}
      openMdError={openMdError}
      setOpenMdError={setOpenMdError}
      errorMsg={stringJp.err_get_list_ranks}
    >
      {!isLoading && (
        <ContentRender
          userInfo={userInfo}
          errorMsg={errorMsg}
          isFetchRanksError={!!errorMsgFetchRanks}
          isOnline={isOnline}
          navigate={navigate}
          listRanks={listRanks}
          setSelectedRank={setSelectedRank}
          saveLastLocation={saveLastLocation}
          setOpenMdOutRank={setIsOpenModalOutRank}
          handleClickRegisterRank={handelClickBtnRegisterRank}
          setErrorIntroduceCodeMsg={setErrorIntroduceCodeMsg}
          setOldIntroduceCode={setOldIntroduceCode}
          oldIntroduceCode={oldIntroduceCode}
          errorIntroduceCodeMsg={errorIntroduceCodeMsg}
          continueNext = {continueNext}
        />
      )}

      {isOpenModalOutRank && (
        <ModalConfirmOutRank
          visible={isOpenModalOutRank}
          setVisible={setIsOpenModalOutRank}
          refetchRank={fetchUserInfo}
        />
      )}

      <ModalWarning
        visible={visibleErrorIntroduceCode}
        setVisible={setVisibleErrorIntroduceCode}
        errorMsg={errorIntroduceCodeMsg}
      />
    </MainLayout>
  );
};

export default HomeScreen;
