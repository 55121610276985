import React, { useState, createContext, useContext, useEffect } from 'react'
import { getListRank } from '../actions/memberInfo'
import {
    getListAvailableRanks,
    checkHadCreditCard, getSelectionState
} from '../functions'

export const RankContext = createContext()

export const useRank = () => useContext(RankContext)

const userDefaultData = {
    accountId: "000000000",
    accountName: '',
    rankId: '',
    rankName: '',
    startDate: null,
    expDate: null,
    differenceGold: null,
    differenceSilver: null,
    totalPurchasePrice: null
}

const paymentDefaultData = {
    point: false,
    creditCard: {
        // registered: checkHadCreditCard(),
        usable: false
    },
    payPay: false
}

const RankContextProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [userInfo, setUserInfo] = useState(userDefaultData)
    const [listRanks, setListRanks] = useState([])
    // Rank selected in list options
    const selectionState = getSelectionState()
    const [selectedRank, setSelectedRank] = useState(
        selectionState?.rank
    )
    const [openMdRejoinPrevRank, setOpenMdRejoinPrevRank] = useState(false)
    const [pointBalance, setPointBalance] = useState(
        selectionState?.points ?? 0
    )
    const [paymentMethods, setPaymentMethods] = useState(
        selectionState?.method ?? paymentDefaultData
    )

    useEffect(() => {
        return () => setErrorMsg('')
    }, [])

    const resetContext = () => {
        setUserInfo(userDefaultData)
        setSelectedRank(null)
        // setListRanks([])
        setPointBalance(0)
        setPaymentMethods(paymentDefaultData)
    }

    // Fetch and filter list ranks
    const fetchListRanks = (currentRankId = '') => {
        setIsLoading(true)
        const handleDataRpGetListRank = res => {
            if (res?.status === 200 && res?.data?.success) {
                const dataFakeCP200 = {
                    "success": true,
                    "message": null,
                    "account_rank_group_list": [
                        {
                            "account_rank_group_id": "6",
                            "account_rank_group_name": "GOLD 200",
                            "admission_fee": 0,
                            "is_give_point": true
                        },
                        {
                            "account_rank_group_id": "5",
                            "account_rank_group_name": "SILVER 200",
                            "admission_fee": 5000,
                            "is_give_point": false
                        },
                        {
                            "account_rank_group_id": "7",
                            "account_rank_group_name": "BRONZE 200",
                            "admission_fee": 0,
                            "is_give_point": false
                        }
                    ]
                }

                const dataFakeCP300 = {
                    "success": true,
                    "message": null,
                    "account_rank_group_list": [
                        {
                            "account_rank_group_id": "8",
                            "account_rank_group_name": "GOLD 300",
                            "admission_fee": 0,
                            "is_give_point": true
                        },
                        {
                            "account_rank_group_id": "9",
                            "account_rank_group_name": "SILVER 300",
                            "admission_fee": 0,
                            "is_give_point": false
                        },
                        {
                            "account_rank_group_id": "4",
                            "account_rank_group_name": "BRONZE 300",
                            "admission_fee": 3000,
                            "is_give_point": true
                        }
                    ]
                }

                const dataFakeCP100 = {
                    "success": true,
                    "message": null,
                    "account_rank_group_list": [
                        {
                            "account_rank_group_id": "1",
                            "account_rank_group_name": "BLANDE_Prime_GOLD",
                            "admission_fee": 5000,
                            "is_give_point": true
                        },
                        {
                            "account_rank_group_id": "2",
                            "account_rank_group_name": "BLANDE_Prime_SILVER",
                            "admission_fee": 3000,
                            "is_give_point": true
                        },
                        {
                            "account_rank_group_id": "3",
                            "account_rank_group_name": "BLANDE_Prime_BRONZE",
                            "admission_fee": 0,
                            "is_give_point": true
                        }
                    ]
                }
                const { account_rank_group_list: listRanksAPI } = res.data

                setErrorMsg('')
                setListRanks(currentRankId
                    ? getListAvailableRanks(currentRankId, listRanksAPI)
                    : listRanksAPI
                )
            }
            else
                setErrorMsg(res?.data?.message)

            setIsLoading(false)
        }

        getListRank(handleDataRpGetListRank);
    }

    return (
        <RankContext.Provider
            value={{
                userInfo, setUserInfo,
                isLoading, setIsLoading,
                errorMsg, setErrorMsg,
                selectedRank, setSelectedRank,
                listRanks, setListRanks,
                resetContext,
                fetchListRanks,
                openMdRejoinPrevRank, setOpenMdRejoinPrevRank,
                pointBalance, setPointBalance,
                paymentMethods, setPaymentMethods
            }}
        >
            {children}
        </RankContext.Provider>
    )
}

export default RankContextProvider
